@use "css/IncludeMedia";
@use "css/Colors";
@use "css/Section";

.MastheadSection {
  @extend .Container;

  // TODO replace this with a static map (not necessarily in SCSS)
  // background-color: silver;
  background-image: url('../../images/report_bg.jpeg');
  background-size: cover;
  .Address {
    background-color: white;
  }

  .Heading {
    font-size: 2em;
    font-weight: bold;
  }

  .AddressText {
    color: Colors.$text-light-on-light;
  }
}

// Gradient border bar
.MastheadSection {
  $border-thickness: 4px;
  position: relative;
  background-clip: padding-box;
  border-bottom: solid $border-thickness transparent;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin-bottom: -$border-thickness;
    border-radius: inherit;
    background: linear-gradient(to right, #f25e5f, #f9a245);
  }
}

.Address {
  $address-padding: 1.5rem;
  padding-left: $address-padding;
  padding-bottom: $address-padding;
  .Heading {
    padding-top: $address-padding;
    padding-bottom: $address-padding;
  }
  .AddressText {
    padding-bottom: $address-padding;
    padding-left: calc($address-padding / 2);
    padding-right: calc($address-padding / 2);
  }
}

.MastheadSection {
  // Prevent collapsing parent-child margins
  padding-top: 0.1px;

  .Address {
    // TODO: Size to show the building in the map above the address
    // TODO: And on mobile
    @include IncludeMedia.media(">phone") {
      width: 320px;

      margin-top: 24px;
      margin-left: 24px;
      margin-bottom: 96px;
    }
  }
}
