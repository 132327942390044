@use "css/Colors.scss";
@use "css/IncludeMedia";

.Page {
  position: absolute;
  background: white;
  width: 40rem;
  padding: 3em;
  border-radius: 25px;
  left: 3em;
  top: 5em;

  @include IncludeMedia.media(">phone", "<=tablet") {
    left: 0em;
    top: 4.5em;
    width: auto;
    padding: 1em;
    margin: 0em 1em;
  }
}

.Heading {
  color: Colors.$text-primary-orange;
  font-size: 3.25rem;
}

.SubHeading {
  font-size: 1.2em;
  margin: 0.75rem 0;
  color: Colors.$text-default;
}

.RotateText {
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0 0 2em 0px;
  color: Colors.$text-default;
}

.TextboxTitle {
  color: gray;
}
