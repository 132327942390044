@use "css/Section";
@use "../../css/Colors";
@use "../../css/FloatingCell";

.ImplementationOptionsSection {
  @extend .Container;
}

.FloatingCells {
  @include FloatingCell.list;
  gap: 32px;
  margin-bottom: 32px;
  justify-content: center;
}

.Title {
  color: Colors.$text-default;
  font-weight: bolder;
  text-align: center;
  padding: 1em 0 0 0;
  font-size: 2em;
}

.GetStarted{
  margin-bottom: 2em;
  text-align: center;
}
