@use "css/Section";
@use "../../css/Colors";
@use "css/IncludeMedia";

.TestimonialsSection {
  @extend .Container;

  background-color: Colors.$background-light-blue;
  
  line-height: 1.5rem;

  .SectionInner {
    margin: auto;
    padding: 5rem 0;
    width: 1200px;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;

    @include IncludeMedia.media(">phone", "<=tablet") {
      padding: 5rem 2rem;
      width: 85%;
    }
  }

  .Image {
    flex: 1 1 200px;
    max-width: 100%;
    width: 300px;
  }

  .Content {
    flex: 1 0 200px;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    //justify-content: space-between;

    align-items: flex-start;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 3rem;
    margin-bottom: 2.5rem;
  }

  .Quote {
    max-width: 440px;
  }
  .Citation {
    margin: 2rem 0;
  }
}
