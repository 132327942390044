@use "../../css/Colors";

.HireBlocPower {}

.HrSection{
    background-color: Colors.$background-light-blue;

    hr{
        margin: 0 1em 0 1em;
        border: 1px solid Colors.$border-light;
    }
}
