@use "css/IncludeMedia";
@use "css/Colors.scss";

.RequalificationForm {
    background-color: Colors.$backgorund-white;
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        display: grid;
      
        label {
          width: 90%;
          display: flex;
          text-transform: uppercase;
          float: left;
          height: 2em;
      
          @include IncludeMedia.media("<=tablet") {
            height: auto;
            margin-top: 1em;
            width: 100%;
          }
        }
        
        input[type=checkbox] { width: auto; vertical-align: middle; transform: scale(1.3); }
    }

    .FormHeading {
        font-size: 1.5rem;
        font-weight: bolder;
        margin-bottom: 0.5rem
    }
    
    .FormSubHeading {
        font-size: 1rem;
        font-weight: bolder;
        margin-bottom: 1rem;
        color: Colors.$text-gray;
    }
      
}



.ButtonSection{
    text-align: right;
    margin-top: 1rem;
}
