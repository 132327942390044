@use "css/Colors.scss";
@use "css/IncludeMedia";

.FullBleedHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: Colors.$text-default;

    .Logo {
      height: 60px;
      margin-left: 3em;

      @include IncludeMedia.media("<=tablet") {
        margin-left: 0em;
      }
    }
}
