@use "css/Colors.scss";

.AddressCollectionWidget {}

.InputGroup{}

.AddressInput{
  width: 98%;
  border-radius: 25px;
  border: 1px solid #c2c3c5;
  height: 2rem;
  margin: 0.75em 0 1.5em 0;
  padding: 0.5em;
}

.NextButtonSection{
  text-align: right;
}

.Error{
  color: Colors.$text-default;
  margin: -1.5rem 0 1rem 0;
  font-size: 0.75em;
}
