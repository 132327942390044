@use "../../css/Colors";

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1024px);
.ImplementationOption {
    border: 1px solid Colors.$border-light;
    margin: 1em 0.5em 0.5em 0.5em;
    width: 40%;

    @media only screen and (max-width: 986px){
        width: 100%;
    }
}

.Title{
    background: Colors.$text-default;
    text-align: center;
    text-transform: uppercase;
    color: white;
    padding: 0.4rem 0;
    font-size: 0.75em;
}
