.TooltipInfoIcon {
    display: -webkit-inline-box;
}

.InfoIcon {
    cursor: pointer;
    height: 30px;
    vertical-align: super;
}

.Tooltip {
    width: 40%;
    font-size: 0.75em;
    text-align: justify;
}
