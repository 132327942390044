@use "../../css/FloatingCell";
@use "../../css/Colors";
@use "../../css/IncludeMedia";
@use "css/Section";

.FloatingCells {
  text-align: left;
  @include FloatingCell.list;
  gap: 32px;
  margin-bottom: 32px;
  padding: 0 2em;
}

.ReportSection {
  @extend .Container;
  background: linear-gradient(
    180deg, rgba(236,248,248,1) 0%, rgba(236,248,248,0.2007177871148459) 100%);
  text-align: center;
  padding-top: 2em;
}

$icon-list-el-padding: 1em;
.List {
  list-style-type: none;

  .ListItem {
    margin: $icon-list-el-padding 0;
    display: flex;
    justify-content: flex-start;
    gap: $icon-list-el-padding;
    align-items: center;
  }
  .Icon {
  }
  .ListItemContent {
  }
}

.ReportSection {
  &_InfoBlock {
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    width: 40%;
    
    @include FloatingCell.cell;
    background-color: Colors.$backgorund-white;

    @include IncludeMedia.media("<=tablet") {
      width: auto;
    }
    @include IncludeMedia.media(">phone") {
      //max-width: 50%;
    }

    &_label {
      padding: 12px;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 2px solid Colors.$background-grey;
    }

    &_value {
      font-size: 4em;
      color: Colors.$text-primary-orange;
      text-align: center;
      margin: 24px 0;
    }

    &_blockcontent {
      margin: 0 $icon-list-el-padding;
    }

    &_unit {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 24px;
    }
  }
}
