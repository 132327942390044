@mixin list {
  display: flex;
  flex-wrap: wrap;
}

@mixin cell {
  border: 1px;
  background-color: white;

  flex: auto;
}
