@use "css/Colors.scss";
@use "css/IncludeMedia";

.BuildingForm {}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
  display: grid;

  label {
    width: 25rem;
    display: flex;
    text-transform: uppercase;
    float: left;
    height: 2em;

    @include IncludeMedia.media("<=tablet") {
      height: auto;
      margin-top: 1em;
      width: 100%;
    }
  }

  select {
    width: 15rem;
    border: 1px solid #c2c3c5;
    padding: 0.3em;
    @include IncludeMedia.media("<=tablet") {
      width: 100%;
    }
  }
  input {
    width: 14.36rem;
    border: 1px solid #c2c3c5;
    padding: 0.3em;
    @include IncludeMedia.media("<=tablet") {
      width: 100%;
    }
  }
  input[type=checkbox] { width: auto; vertical-align: middle; transform: scale(1.3); }
}

.ButtonSection{
  text-align: right;
  margin-top: 1rem;
}

.FormHeading {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 1rem
}

.FormSubHeading {
  font-size: 0.75rem;
  font-weight: bolder;
  margin-bottom: 1rem;
  color: gray;
}

.isRequired {
  color: Colors.$text-error;
  font-size: 0.4em;
  text-align: right;
  margin-top: -1em;
  margin-bottom: 1em;
  @include IncludeMedia.media("<=tablet") {
    margin-top: 0;
  }
}

.SqftInput {
  position: relative;
  z-index: 2;
  line-height: 15px;
  height: 15px;
  font-size: 0.85rem;
  float: left;
  width: 11rem;
  margin-bottom: 0;
  display: table-cell;
  padding: 0.44em;
  border: 1px solid #ccc;
  margin: 0;
  border-right: none;
  @include IncludeMedia.media("<=tablet") {
    width: 75%;
  }
}

.Suffix {
  width: 15rem;
  float: left;
  @include IncludeMedia.media("<=tablet") {
    width: 100%;
  }
}

.InputGroup {
  position: relative;
  display: table;
  border-collapse: separate;
}

.InputGroupAddon {
  padding: 6px 9px;
  font-weight: 400;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 3em;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-left: none;
  line-height: 15px;
  height: 15px;
  font-size: 0.85rem;
  @include IncludeMedia.media("<=tablet") {
    width: 20%;
  }
}
