@use "css/Colors.scss";
@use "css/IncludeMedia";
@use "css/Section";

.FAQSection {
    @extend .Container;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    background: Colors.$background-grey;

    li::marker {
        font-size: 1em;
    }
}

.FAQTitle {
    font-size: 2rem;
    color: Colors.$text-default;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2em;
    text-align: center;
}

:global {
    .faq-row-wrapper {
        padding: 10px;
        background: Colors.$background-grey !important;
    }
    
    .faq-row-wrapper .faq-body .faq-row {
        padding: 0.75em 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        margin: 2em 1em;
        background: Colors.$backgorund-white;
    }
    
    .faq-row-wrapper .faq-body .faq-row .row-title {
        font-size: larger;
        color: Colors.$text-default;
        padding-left: 2em !important;
    }
    
    .faq-row-wrapper .faq-body .faq-row .row-content-text {
        padding: 0em 2em !important;
        font-size: medium !important;
        color: Colors.$text-gray !important;
        height: auto;
    }
  }