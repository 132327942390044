@use "css/Colors.scss";

.Button {
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  padding: 16px 32px;

  text-transform: uppercase;
  color: Colors.$text-light-on-dark;

  background-color: Colors.$button-background-idle;
  &:hover {
    background-color: Colors.$button-background-hover;
  }
}
