.DIYSteps {
  height: 30rem;
}

.CheckBoxBullet {
  list-style-image: url('../../images/icons/Checkbox_Unchecked.svg');
}

li::marker {
  font-size: 1.8em;
}
