@use "css/Section";

.ReportHeaderSection {
  @extend .Container;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .Logo {
    height: 60px;
  }
}
