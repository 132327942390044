$background-grey: #f4f6f8;
$backgorund-white: #ffffff;
$background-light-blue: #ecf8f8;

$border-light: #c9e4e3;

$text-default: #062d40;
$text-gray: #585858;
$text-primary-orange: #e68b63;
$text-error: #ff0000;
$text-light-on-dark: #ffffff; // TODO define text-default-on-dark
$text-light-on-light: lighten(desaturate($text-default, 80%), 20%);

$button-background-idle: #e68b63;
$button-background-hover: saturate($button-background-idle, 80%);
