@use "../../css/Colors";

.NoHeadaches {
  background-color: Colors.$background-light-blue;

  .Heading {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.33rem;
    padding: 24px 0 0 16px;
  }

  .SubHeading {
    padding: 0 0 24px 16px;
  }
}
