@use "css/IncludeMedia";

.RequalificationPage {
    position: absolute;
    background: white;
    width: 40rem;
    padding: 3em;
    border-radius: 25px;
    left: 3em;
    top: 5em;

    @include IncludeMedia.media("<=tablet") {
        left: 0em;
        top: 4.5em;
        width: auto;
        padding: 1em;
        margin: 2em 1em;
        position: inherit;
    }
}
