@use "../../css/FloatingCell";
@use "../../css/Colors";

.LabeledMetricHireBP {
  background: Colors.$background-light-blue !important;
  padding: 30px 0 0 16px;
  @include FloatingCell.cell;

  &_label {
    padding: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &_value {
    font-size: 4em;
    text-align: center;
  }
  &_unit {
  }

  .label {
    font-size: 1em;
    padding: 0 0 0.75rem 0;
    text-transform: uppercase;
    color: Colors.$text-default;
    font-weight: bolder;
  }

  .table {
    display: table-row;
    color: Colors.$text-default;
  }

  .value {
    font-size: 2rem;
    display: inline-block;
    padding: 0 0 24px 0;
  }

  .unit {
    font-size: 0.9em;
    display: inline-block;
    vertical-align: super;
    padding: 0 0 0 1em;
    text-transform: uppercase;
    line-break: auto;
  }
}

.LabeledMetricDIY {
  background: Colors.$background-light-blue !important;
  padding: 2.35rem;
  text-align: center;
  @include FloatingCell.cell;

  &_label {
    padding: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &_value {
    font-size: 4em;
    text-align: center;
  }
  &_unit {
  }

  .label {
    font-size: 1em;
    padding: 0 0 0.75rem 0;
    text-transform: uppercase;
    color: Colors.$text-default;
    font-weight: bolder;
  }
  
  .table {
    display: table-row;
    color: Colors.$text-default;
  }
  
  .value {
    font-size: 4rem;
    display: inline-block;
    padding: 0 0 24px 0;
  }
  
  .unit {
    font-size: 0.9em;
    display: inline-block;
    vertical-align: super;
    padding: 0 0 0 1em;
    text-transform: uppercase;
    line-break: auto;
  }
}
