@use "css/Colors.scss";

.ReportLoadingScreen {
    background: Colors.$backgorund-white;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
}

.LoadingContent {
    font-size: 1.25rem;
    color: Colors.$text-default;
}

.LoadingIconArea {}

.LoadingIcon {
    height: 17em;
}