.HireSteps {
    position: relative;
    height: 30rem;
    ul {
        list-style-type: disc;
        margin: 1rem;
        li {
          font-weight: bold;
        }
        li::marker {
            font-size: 1.8em;
        }

        // Nested UL inherits all of the above, plus:
        ul {
          list-style-type: disc;
          list-style-image: none;
          margin: 0 !important;
          padding: 0.75em;
          li {
            font-weight: normal;
            margin: 0rem 0rem 1rem 0rem;
          }
          li::marker {
            font-size: 1.2em;
          }
        }
    }
}

.CheckBoxBullet{
    list-style-image: url('../../images/icons/Checkbox_Checked.svg');
}

.ConditionSection {
    position: absolute;
    bottom: 0px;
}

.AsteriskText {
    position: absolute;
    bottom: 20px;
    left: 20px;
}
